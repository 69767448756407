import React from "react";
import {
  ServicesContainer,
  ServicesShadowCardWrapper,
  ServicesShadowCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "../Services/ServicesElements";
import consultationSvg from "../../images/consultation.svg";
import personalizedSvg from "../../images/personalized_plan.svg";
import Icon1 from "../../images/svg-1.svg";
import { VscWorkspaceUnknown } from "react-icons/vsc";
// import useGoogleAnalytics from "../../components/googleanalytics";

function StepByStepGuide() {
  // Initialize Google Analytics

  return (
    <ServicesContainer id="services">
      <h1 className="text-4xl text-black font-medium sm:text-3xl">
        <p className="flex items-center justify-center text-black font-bold py-2 px-4 gap-4">
          <VscWorkspaceUnknown className="bold-icon" />
          How It Works
        </p>
      </h1>
      <br />
      <ServicesShadowCardWrapper>
        <ServicesShadowCard>
          <ServicesIcon src={consultationSvg} />
          <ServicesH2>Consultation</ServicesH2>
          <ServicesP>
            Start with a free consultation to discuss your challenges and goals.
          </ServicesP>
        </ServicesShadowCard>
        <ServicesShadowCard>
          <ServicesIcon src={personalizedSvg} />
          <ServicesH2>Personalized Plan</ServicesH2>
          <ServicesP>
            Receive a customized workout and nutrition plan designed for you
          </ServicesP>
        </ServicesShadowCard>
        <ServicesShadowCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Ongoing Support</ServicesH2>
          <ServicesP>
            Benefit from continuous support and plan adjustments as needed
          </ServicesP>
        </ServicesShadowCard>
      </ServicesShadowCardWrapper>
    </ServicesContainer>
  );
}

export default StepByStepGuide;
