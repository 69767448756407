import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesShadowCardWrapper,
  ServicesShadowCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "../Services/ServicesElements";
import Icon3 from "../../images/svg-3.svg";
import ProgressTrack from "../../images/progress_track.svg";
import FlexibleSchedule from "../../images/schedule.svg";
import Guidance from "../../images/Guidance.svg";
// import useGoogleAnalytics from "../../components/googleanalytics";

function ChooseAdaptFit() {
  // Initialize Google Analytics

  return (
    <ServicesContainer id="services">
      <ServicesH1>
        <p className="flex items-center justify-center text-black font-bold py-2 px-4 rounded-3xl mt-4">
          <img
            src="./hand.png"
            alt="claim consultation"
            className="w-12 h-12 sm:w-10 sm:h-10 mr-2"
          />
          Why Choose Adaptfit?
        </p>
      </ServicesH1>
      <h2>
        At AdaptFit, we create personalized fitness and nutrition plans to help
        you achieve your health goals, whether it's losing weight, building
        muscle, or managing a specific health condition.
      </h2>
      <br />
      <ServicesShadowCardWrapper>
        <ServicesShadowCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Customized plans</ServicesH2>
          <ServicesP>
            Tailored workouts and meal plans to suit your lifestyle
          </ServicesP>
        </ServicesShadowCard>
        <ServicesShadowCard>
          <ServicesIcon src={ProgressTrack} />
          <ServicesH2>Progress Tracking</ServicesH2>
          <ServicesP>
            Monitor your progress with our app and personal check-ins
          </ServicesP>
        </ServicesShadowCard>
        <ServicesShadowCard>
          <ServicesIcon src={FlexibleSchedule} />
          <ServicesH2>Flexible Scheduling</ServicesH2>
          <ServicesP>Fit your workouts into your busy schedule</ServicesP>
        </ServicesShadowCard>
        <ServicesShadowCard>
          <ServicesIcon src={Guidance} />
          <ServicesH2>Expert Guidance</ServicesH2>
          <ServicesP>
            Access to certified trainers and nutrition specialists
          </ServicesP>
        </ServicesShadowCard>
      </ServicesShadowCardWrapper>
    </ServicesContainer>
  );
}

export default ChooseAdaptFit;
