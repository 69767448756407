import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import SuccessModal from "../components/SuccessModal";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import FastSchedulingImg from "../images/fast_scheduling.PNG";
// import ClaimConsultation from "../images/claim_consultation.PNG";

const Contact = ({ isFromGoogleAdd = false }) => {
  console.log("isfrom google add",isFromGoogleAdd);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errors, setErrors] = useState({});
  const form = useRef();
  const bgColorClass = isFromGoogleAdd
    ? "bg-black text-white"
    : "bg-white text-black";

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleChange = (phoneNumber) => {
    if (phoneNumber !== undefined) {
      setValue(phoneNumber);
      setIsValid(isValidPhoneNumber(phoneNumber));
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneNumber: isValidPhoneNumber(phoneNumber)
          ? ""
          : "Invalid phone number",
      }));
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const formErrors = {};
    if (!isValidPhoneNumber(value)) {
      formErrors.phoneNumber = "Please enter a valid phone number.";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    setModalIsOpen(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setValue("");
    setErrors({});
  };

  return (
    <>
      {!isFromGoogleAdd && (
        <div
          style={{
            backgroundImage: "url('/contactus.png')",
            backgroundSize: "cover",
          }}
          className="font-extrabold h-28 flex items-center justify-center text-white text-center text-6xl md:text-4xl"
        >
          Contact Us
        </div>
      )}
      <SuccessModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      {!isFromGoogleAdd && (
        <h1 className="text-center text-2xl font-bold p-2 text-black">
          You’re just one mail or call away
        </h1>
      )}
      <section className={`${bgColorClass} my-5`}>
        <div className={`container ${isFromGoogleAdd ? '' : 'mx-auto px-6'}`}>
            <div className={`${bgColorClass}`}>
              <div
                className={`w-full px-8 py-3 mx-auto overflow-hidden ${bgColorClass} rounded-lg shadow-2xl dark:bg-gray-900 lg:max-w-xl shadow-gray-300/50 dark:shadow-black/50`}
              >
                {!isFromGoogleAdd ? (
                  <h1 className="text-lg text-center font-medium text-gray-700">
                    Whether it's to ask about our service or to think of the
                    right approach for you. We are here to help.
                  </h1>
                ) : <>
                  <p className="mt-4 text-sm text-center font-medium">
                    Sign up today to start transforming your life! The first 50
                    sign-ups will receive an exclusive 30% discount on their
                    first three months. Don't miss out - spots are limited!
                  </p>
                  <br />
                  </> }
                <form ref={form} className="mt-6" onSubmit={sendEmail}>
                  <div className="flex-1">
                    <label className="left block mb-2 text-sm text-left text-gray-600 dark:text-gray-200">
                      Full Name
                    </label>
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      pattern="[A-Za-z ]{2,20}"
                      required
                      className={`block px-5 py-3 mt-2  ${bgColorClass} w-full text-gray-700 placeholder-gray-400 border  rounded-md focus:border-green-400 focus:ring-green-400 focus:outline-none focus:ring focus:ring-opacity-40`}
                    />
                    {errors.name && (
                      <p className="mt-2 text-sm text-red-600">{errors.name}</p>
                    )}
                  </div>
                  {!isFromGoogleAdd && (
                    <div className="flex-1 mt-6">
                      <label className="block mb-2 text-sm text-left text-gray-600 dark:text-gray-200">
                        Email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        required
                        className={`block px-5 py-3 mt-2 ${bgColorClass} w-full text-gray-700 placeholder-gray-400 border  rounded-md focus:border-green-400 focus:ring-green-400 focus:outline-none focus:border focus:ring-opacity-40`}
                      />
                      {errors.email && (
                        <p className="mt-2 text-sm text-red-600">
                          {errors.email}
                        </p>
                      )}
                    </div>
                  )}
                  <div className="flex-1 mt-6">
                    <label className="block mb-2 text-sm text-left text-gray-600 dark:text-gray-200">
                      Phone Number
                    </label>
                    <PhoneInput
                      defaultCountry="IN"
                      value={value}
                      onChange={handleChange}
                      name="phoneNumber"
                      placeholder="Enter phone number"
                      required
                      className={`px-5 py-3 mt-2  ${bgColorClass} w-full text-gray-700 border placeholder-gray-400 ${
                        isValid ? "border-gray-200" : "border-red-500"
                      } rounded-md`}
                    />
                    {!isValid && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>

                  <div className="w-full mt-6">
                    <label className="block mb-2 text-sm text-left text-gray-600 dark:text-gray-200">
                      Message
                    </label>
                    <textarea
                      name="message"
                      required={isFromGoogleAdd ? "" : "required"}
                      className={`block h-32 px-5 py-3 mt-2  ${bgColorClass} w-full text-gray-700 placeholder-gray-400 border  rounded-md md:h-48 focus:border-green-400 focus:ring-green-400 focus:outline-none focus:ring focus:ring-opacity-40`}
                      placeholder="Message"
                    ></textarea>
                    {errors.message && (
                      <p className="mt-2 text-sm text-red-600">
                        {errors.message}
                      </p>
                    )}
                  </div>
                  <div className="flex justify-center">
                  <button className="mt-6 text-sm tracking-wide capitalize transition-colors duration-300 transform hover:bg-green-400 focus:outline-none focus:ring focus:ring-green-300 focus:ring-opacity-50 bg-green-500 text-black py-3 px-6 rounded-full font-medium">
                    {isFromGoogleAdd ? (
                      <p className="flex justify-center items-center">
                        <span className="text-center text-black text-xl ml-2">
                          <b>CLAIM FREE</b> CONSULTATION
                        </span>
                      </p>
                    ) : (
                      "Get in touch"
                    )}
                  </button>
                  </div>
                </form>
                <br />
                {isFromGoogleAdd &&
                <div className="flex items-center justify-center mb-10">
                  <br />
                  <img
                    src={FastSchedulingImg}
                    alt="fast scheduling"
                    color="blue"
                    className="w-15 h-12 mr-2"
                  />
                  We will schedule your consultation within 24 hours of Signing
                  Up
                </div>}
              </div>
            </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
