import React from "react";

export const ServicesContainer = ({ children, ...rest }) => (
  <div
    {...rest}
    className="h-full -mt-10 p-4 md:h-full sm:h-full flex flex-col justify-center items-center bg-white"
  >
    {children}
  </div>
);

export const ServicesWrapper = ({ children }) => (
  <div className="my-0 flex flex-wrap justify-center gap-4 items-center py-0 md:py-0 md:px-5">
    {children}
  </div>
);

export const ServicesCard = ({ children, isFromGoogleAdd = false }) => (
  <div className="bg-white w-80 h-96 flex flex-col justify-start items-center rounded-xl max-h-100 p-8 shadow-lg transition-all ease-in-out duration-200 cursor-pointer hover:transform-gpu hover:scale-105 hover:transition-all hover:ease-in-out hover:duration-200">
    {children}
  </div>
);

export const ServicesIcon = ({ src, alt, ...rest }) => (
  <img src={src} alt={alt} {...rest} className="h-40 w-40 mb-3" />
);

export const ServicesH1 = ({ children }) => (
  <h1 className="text-4xl text-black font-medium mb-10 sm:text-3xl">
    {children}
  </h1>
);

export const ServicesH2 = ({ children }) => (
  <h2 className="text-base font-black mb-3">{children}</h2>
);

export const ServicesP = ({ children }) => (
  <p className="text-base text-center">{children}</p>
);

export const ServicesShadowCardWrapper = ({ children }) => (
  <div className="flex gap-8 my-0 flex flex-wrap justify-center gap-4 items-center py-0 md:py-0 md:px-5">
    {children}
  </div>
);

export const ServicesShadowCard = ({ children }) => (
  <div
    style={{
      boxShadow: "5px 3px 6px rgb(28,174,110)",
    }}
    initial={{ opacity: 0, x: -50 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.8, delay: 0.6 }}
    className="bg-white w-80 h-96 relative max-h-100 p-8 w-80 h-96 justify-start flex bg-white flex-col items-center justify-center p-6 rounded-xl transform"
  >
    {children}
  </div>
);
